var __jsx = React.createElement;
import { Box, Heading, Text } from '@playful/design_system';
import React from 'react';
export var SectionHeading = function SectionHeading(_ref) {
  var children = _ref.children,
    subtitle = _ref.subtitle,
    subtitleRight = _ref.subtitleRight;
  return __jsx(Box, {
    display: 'flex',
    flexDirection: 'column',
    gap: 1
  }, __jsx(Heading, {
    as: "h1",
    size: 'lg',
    color: 'gray.800',
    userSelect: 'text'
  }, children), subtitle && __jsx(Box, {
    display: 'flex',
    justifyContent: {
      base: 'space-between',
      md: 'space-between'
    },
    flexDirection: {
      base: 'column',
      md: 'row'
    },
    gap: 1
  }, __jsx(Text, {
    fontSize: {
      base: 'sm',
      md: 'md'
    },
    color: 'gray.700',
    userSelect: 'text'
  }, subtitle), subtitleRight));
};